import { message } from 'antd';
import { REACT_APP_API_KEY } from '../constant/data';
import axios from '../services/Api';

import Http from 'axios';

export const REGISTER = 'auth/LOGIN';
export const RESET = 'auth/RESET';
export const GET_DATA = 'GET_DATA';
export const LOG_OUT = 'LOG_OUT';
export const CHANGEPASS = 'CHANGEPASS';
export const GET_INFO_USER = 'GET_INFO_USER';
export const CHECK_SUPLIER = 'CHECK_SUPLIER';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const initState = {
  accountDetails: null,
  token: null,
  checkSupplier: false,
};

export const getDataAction = (data) => ({
  type: GET_DATA,
  data,
});

// reset data
export const reset = () => ({
  type: RESET,
});

export const login = (payload, meta) => async (dispatch) => {
  dispatch({ type: LOGIN });
  try {
    const response = await Http.post(`${REACT_APP_API_KEY}/auth/login-admin`, payload);
    if (response?.status === 200 ) {
      dispatch({
        type: LOGIN_SUCCESS,
        data: response?.data,
      });
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
      if(response?.data?.status != 200){
        !!response?.data?.message && message?.error(response?.data?.message || '')
      }
    }
   
  } catch (error) {
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};

export const register = (body, meta) => async (dispatch) => {
  dispatch({ type: REGISTER });
  try {
    const response = await Http.post(`${REACT_APP_API_KEY}/auth/save/admin`, body);
    if (response?.data?.status?.statusCode === 200) {
      if (meta && meta.onSuccess) {
        meta.onSuccess(response);
      }
    } else {
      if (meta && meta.onError) {
        meta.onError(response);
      }
    }
  } catch (error) {
    meta.onError(error);
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  return (dispatch) => {
    dispatch({
      type: LOG_OUT,
    });
  };
};

export const changPassLogin = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: CHANGEPASS,
      data: await axios.post(`/auth/changepassword`, body),
    });
    meta.onSuccess(result);
  } catch (err) {
    meta.onError(err);
  }
};

export const getInfoUser = (params) => async (dispatch) => {
  const result = await dispatch({
    type: GET_INFO_USER,
    data: await axios.get(`/auth/info`, params),
  });
  return result;
};

export const checkSuppliers = (params) => async (dispatch) => {
  const result = await dispatch({
    type: CHECK_SUPLIER,
    data: await axios.get(`/supplier/check`, params),
  });
  return result;
};

export const approveUser = (body, meta) => async (dispatch) => {
  try {
    const result = await dispatch({
      type: 'APPROVE_USER',
      data: await axios.post(`/community/approve`, body),
    });
  } catch (err) {
    message.success("Phê duyệt thất bại!")
  }
};

// Main
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.data?.accessToken);
      return {
        ...state,
        accountDetails: action?.data,
        token: action.data?.accessToken,
        checkSupplier: action?.data?.user?.existSupplier,
      };
    case CHECK_SUPLIER:
      return {
        ...state,
        checkSupplier: action.data?.data?.data,
      };
    case GET_INFO_USER:
      return {
        ...state,
        infoUser: action.data?.data?.data,
      };
    case RESET:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default authReducer;
