import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { ProfileOutlined } from '@ant-design/icons';

import formatMoney from '../../components/ConvertMoney';
import BookingSelect from '../../constant/selectBooking';
import { addBooking } from '../../reducers/bookingReducer';
import axios from '../../services/Api';

import './Booking.css';

const UpdBooking = () => {
  const location = useLocation();
  const id = location.state.id;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useHistory();
  const [formCustomer] = Form.useForm();
  const [formBooking] = Form.useForm();
  const [form] = Form.useForm();

  const supplierId = localStorage.getItem('supplierId');

  const [userId, setUserId] = useState(0);
  const [totalmoney, setTotalmoney] = useState([0]);
  const [money, setMoney] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [service, setService] = useState([]);
  const serviceList = service?.map((e, i) => ({
    key: e.id,
    value: e.id,
    label: e.name,
    price: e.price,
    description: e.description,
  }));
  const [employee, setEmployee] = useState([]);
  const employeeList = employee?.map((e, i) => ({ key: e.id, value: e.id, label: e.fullName }));
  const [bookingDetails, setBookingDetails] = useState([]);

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  const columns = [
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Giá dịch vụ',
      dataIndex: 'price',
      key: 'price',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Thời gian',
      key: 'time',
      width: '16%',
      render: (t, r, i) => (
        <div gutter={24}>
          <DatePicker
            // value={startTime !== "" ? moment(startTime, 'HH:mm') : null}
            showTime
            format="DD-MM-YYYY kk:mm:ss"
            style={{
              borderRadius: 5,
              border: '1px solid #37B59D',
            }}
            placeholder="Chọn thời gian"
            onChange={onChangeTime(i)}
          />
        </div>
      ),
    },
    {
      title: 'Nhân viên',
      key: 'nv',
      width: '19%',
      render: (t, r, i) => (
        <div className="selectCustomer">
          <BookingSelect
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            placeholder="Chọn nhân viên"
            // value={nameService}
            options={employeeList}
            onChange={onChangeEmployee(i)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCustomer();
    if (supplierId !== '') {
      getDataService();
      getEmployee();
    }
    getDatabyId();
  }, [id]);

  const getDatabyId = () => {
    axios.get(`/booking/get/id?id=${id}`).then((response) => {
      formCustomer.setFieldsValue({
        customerName: response.data.data.customerName,
        phone: response.data.data.phone,
        mail: response.data.data.mail,
        innerNote: response.data.data.innerNote,
      });
    });
  };

  const getDataService = () => {
    axios.get(`/service/get/supplier?supplier=${supplierId}&page=0&size=1000`).then((response) => {
      setService(response?.data?.data);
    });
  };

  const getCustomer = () => {
    axios.get(`/address/province?page=0&size=100`).then((response) => {
      // setProvinces(response?.data?.data)
    });
  };

  const getEmployee = () => {
    axios.get(`/employee/get?supplierId=${supplierId}&page=0&size=1000`).then((response) => {
      setEmployee(response?.data?.data);
    });
  };

  const onIns = (value) => {
    if (
      bookingDetails?.map((e) => e.intendTime)[0] !== undefined &&
      bookingDetails?.map((e) => e.intendTime)[0] !== null &&
      bookingDetails?.map((e) => e.intendTime)[0] !== ''
    ) {
      const data = {
        id: 0,
        totalMoney: money,
        paidMoney: 0,
        discountPercent: 0,
        discountMoney: 0,
        customerName: value?.customerName,
        phone: value?.phone,
        mail: value?.mail,
        innerNote: value?.innerNote !== null ? value?.innerNote : '',
        supplierId: supplierId,
        bookingDetails: bookingDetails,
      };
      // dispatch(
      //   addBooking(userId, data, {
      //     onSuccess: (message) => {
      //       notification.success({
      //         // message: `${message?.data?.data?.message}`,
      //         message: `Thêm booking thành công`,
      //       });
      //       history.push('/booking')
      //     },
      //     onError: (message) => {
      //       notification.error({
      //         // message: `${message?.data?.message}`,
      //         message: `Thêm booking thất bại`,
      //       })
      //     }
      //   })
      // );
    } else {
      notification.error({
        message: `Chọn thời gian dịch vụ`,
      });
    }
  };

  const onChangeTime = (i) => (date, dateString) => {
    let newArr = [...bookingDetails];
    newArr[i].intendTime = dateString;
    setBookingDetails(newArr);
  };

  useEffect(() => {
    const money = sumArray(totalmoney);
    setMoney(money);
  }, [totalmoney]);

  function sumArray(totalmoney) {
    let sum = 0;
    totalmoney.map(function (value) {
      sum += value;
    });
    return sum;
  }

  const onChangeSelect = (id, data) => {
    const arrData = data?.map((e, i) => ({
      id: e.value,
      name: e.name,
      price: e.price,
      description: e.description,
      intendTime: '',
      employeeId: 0,
      serviceUserId: e.value,
    }));
    setDataTable(arrData);
    const arrDataDetails = data?.map((e, i) => ({
      quantity: 1,
      price: e.price,
      intendTime: '',
      employeeId: 0,
      serviceUserId: e.value,
    }));
    setTotalmoney(data?.map((e) => e?.price));
    setBookingDetails(arrDataDetails);
  };

  const onChangeEmployee = (i) => (id, data) => {
    let newArr = [...bookingDetails];
    newArr[i].employeeId = data?.value;
    setBookingDetails(newArr);
  };

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item href="/booking">
              <ProfileOutlined /> <b>Booking</b>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/insBooking">
              <b>Thêm Booking</b>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <br />

      <Form form={formCustomer} onFinish={onIns} labelCol={{ span: 6 }} wrapperCol={{ span: 24 }}>
        <Row span={24} style={{ borderTop: '1px solid #d9d9d9' }}>
          <Col
            span={9}
            style={{
              borderRight: '1px solid #d9d9d9',
              paddingTop: '10px',
              paddingRight: '10px',
            }}
          >
            <Form.Item>
              <h1 style={{ fontWeight: 'bold' }}>Thông tin Thành viên</h1>
            </Form.Item>
            <Row span={24}>
              <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
                <Form.Item
                  label="Họ tên:"
                  style={{ fontWeight: 'bold' }}
                  name="customerName"
                  rules={[{ required: true, message: 'Nhập tên Thành viên' }]}
                >
                  <Input
                    disabled
                    style={{
                      borderRadius: 5,
                      border: '1px solid #37B59D',
                      color: 'black',
                    }}
                    placeholder="Tên Thành viên"
                  />
                </Form.Item>

                <Form.Item
                  label="Điện thoại:"
                  style={{ fontWeight: 'bold' }}
                  name="phone"
                  children
                  rules={[{ required: true, message: 'Nhập số điện thoại' }]}
                >
                  <Input
                    disabled
                    style={{
                      borderRadius: 5,
                      border: '1px solid #37B59D',
                      color: 'black',
                    }}
                    placeholder="Số điện thoại"
                    maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>

                <Form.Item label="Email" style={{ fontWeight: 'bold' }} name="mail">
                  <Input
                    disabled
                    style={{
                      borderRadius: 5,
                      border: '1px solid #37B59D',
                      color: 'black',
                    }}
                    placeholder="Nhập Email"
                  />
                </Form.Item>

                <Form.Item label="Ghi chú" style={{ fontWeight: 'bold' }} name="innerNote">
                  <TextArea
                    autoSize
                    style={{
                      borderRadius: 5,
                      border: '1px solid #37B59D',
                      color: 'black',
                    }}
                    placeholder="Ghi chú"
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ paddingTop: '10px' }}>
                <Form.Item label="Tổng tiền" style={{ fontWeight: 'bold' }}>
                  <Input
                    value={FormatMoney(money) + 'VND'}
                    disabled
                    style={{
                      borderRadius: 5,
                      border: '1px solid #37B59D',
                      fontWeight: 'bold',
                      color: 'red',
                    }}
                    placeholder="Tổng tiền"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={15} style={{ paddingLeft: '10px', paddingTop: '10px' }}>
            <Form form={formBooking} autoComplete="off" hideRequiredMark>
              <Form.Item>
                <h1 style={{ fontWeight: 'bold' }}>Thông tin dịch vụ</h1>
              </Form.Item>

              <Form.Item style={{ fontWeight: 'bold' }}>
                <div className="selectCustomer">
                  <BookingSelect
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder="Chọn dịch vụ"
                    // value={nameService}
                    options={serviceList}
                    onChange={onChangeSelect}
                  />
                </div>
              </Form.Item>

              <Form.Item>
                <Table columns={columns} dataSource={dataTable} size="small" pagination={false} />
              </Form.Item>
            </Form>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button
                style={{
                  marginLeft: '6px',
                  backgroundColor: 'rgb(55, 181, 157)',
                  borderRadius: 5,
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                }}
                type="primary"
                htmlType="submit"
              >
                Xác nhận
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default UpdBooking;
