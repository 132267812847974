import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Login from './screens/Auth/Login/Login';
import Register from './screens/Auth/Register/Register';
import HomeLayout from './screens/DefaultLayout/DefaultLayout';
import Message from './screens/Messager/MessengerMain';
import CreateSupplier from './screens/Supplier/CreateSupplier';
import allReducers from './reducers';
import './App.css';

import decode from 'jwt-decode';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export let store = createStore(allReducers, applyMiddleware(thunk));
const checkAuth = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }
  try {
    const { exp } = decode(token);
    if (exp < parseInt(new Date().getTime() / 1000)) {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/createSupplier" component={CreateSupplier} />
          <Route path="/message" component={Message} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route
            path="/"
            render={(props) =>
              checkAuth() ? (
                <HomeLayout />
              ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
              )
            }
          />
          <Redirect from="/" to="/" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
