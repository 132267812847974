import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Image, Input, Modal, notification, Switch, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';

import { updStaff } from '../../reducers/staffReducer';
import axios from '../../services/Api';
import { REACT_APP_API_KEY, REACT_APP_API_KEY_IMAGE } from '../../constant/data';

const UpdStaff = (props) => {
  const { show, handleCancel, id } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const supplierId = localStorage.getItem('supplierId');

  const [images, setImages] = useState('');

  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });

  useEffect(() => {
    if (id !== null) {
      getDataById();
    }
  }, [show, id]);

  const getDataById = () => {
    axios.get(`/employee/get/id?id=${id}`).then((response) => {
      form.setFieldsValue(response?.data?.data);
      setImages(response?.data?.data?.avatar);
    });
  };

  const handleCancelUpd = () => {
    handleCancel();
    form.setFieldsValue({
      code: '',
      name: '',
      address: '',
      phone: '',
    });
  };

  const handleChangeLogo = (info) => {
    let fileList = [...info.fileList];
    const { status } = info.file;
    if (status === 'done') {
      const urlList = fileList.map((file) => {
        return file.response?.data;
      });
      setImages(urlList[0] || '');
    } else if (status === 'error') {
    }
  };

  const onUpd = (values) => {
    const body = {
      id: id,
      fullName: values?.fullName,
      lastName: values?.lastName,
      firstName: values?.firstName,
      viewName: values?.viewName,
      profile: values?.profile,
      status: values?.status,
      email: values?.email,
      phone: values?.phone,
      avatar: images,
      supplierId: supplierId,
    };
    dispatch(
      updStaff(body, {
        onSuccess: (message) => {
          notification.success({
            message: `Sửa thông tin nhân viên thành công`,
          });
          handleCancelUpd();
        },
        onError: (message) => {
          notification.error({
            message: `Sửa thông tin nhân viên thất bại`,
          });
        },
      })
    );
  };

  return (
    <Modal
      title={`SỬA THÔNG TIN NHÂN VIÊN`}
      open={show}
      onCancel={handleCancelUpd}
      okButtonProps={{ form: 'updStaff', key: 'submit', htmlType: 'submit' }}
    >
      <Form
        form={form}
        id="updStaff"
        onFinish={onUpd}
        autoComplete="off"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
      >
        {/* <Form.Item
          label="Tài khoản"
          name="username"
          style={{ fontWeight: "500" }}
          rules={[{ required: true, message: "Nhập tài khoản" }]}
        >
          <Input placeholder="Tài khoản nhân viên" />
        </Form.Item> */}

        <Form.Item label="Logo" style={{ fontWeight: '500' }}>
          <ImgCrop rotate>
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
              headers={JWT_HEADER}
              method="POST"
              onChange={handleChangeLogo}
            >
              {images ? (
                <Image
                  className="imgLogo"
                  src={`${REACT_APP_API_KEY_IMAGE}${images}`}
                  alt="avatar"
                  preview={false}
                />
              ) : (
                <div style={{ fontWeight: 'bold' }}>
                  <PlusOutlined />
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item
          label="Tên nhân viên"
          name="fullName"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập tên nhân viên' }]}
        >
          <Input placeholder="Tên nhân viên" />
        </Form.Item>

        <Form.Item
          label="Số điện thoại"
          name="phone"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập số điện thoại' }]}
        >
          <Input placeholder="Số điện thoại"  maxLength={10} onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          style={{ fontWeight: '500' }}
          rules={[{ required: true, message: 'Nhập Email' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          label="Trạng thái"
          name="status"
          style={{ fontWeight: '500' }}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UpdStaff;
