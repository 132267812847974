import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  CloseCircleOutlined,
  SaveOutlined,
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
  ReloadOutlined,
  ScheduleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { notification } from 'antd/lib';

import formatMoney from '../../components/ConvertMoney';
import Filter from '../../constant/Filter';
import FilterTime from '../../constant/FilterTime';
import CustomSreach from '../../constant/Sreach';
import { insBill } from '../../reducers/billReducer';
import { changeStatus, updateBookingDetail } from '../../reducers/bookingReducer';
import axios from '../../services/Api';

import TabStatus from './TabStatus';

import dayjs from 'dayjs';
import moment from 'moment';
const POOLING_TIME_DEFAULT = 1000 * 60 * 1;

const Booking = () => {
  const { RangePicker } = DatePicker;
  const history = useHistory();
  const dispatch = useDispatch();

  const dateFormat = 'DD-MM-YYYY';
  const supplierId = localStorage.getItem('supplierId');

  const total = {
    total: 0,
    waiting: 0,
    service: 0,
    served: 0,
    cancelled: 0,
  };

  const [totalPage, setTotalPage] = useState('');
  const [nameStatus, setNameStatus] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [renderDel, setRenderDel] = useState(false);
  const [renderStatus, setRenderStatus] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRowsBig, setSelectedRowsBig] = useState([]);
  const [selectedKeysBig, setSelectedKeysBig] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDateView, setStartDateView] = useState('');
  const [endDateView, setEndDateView] = useState('');
  const [totalStatus, setTotalStatus] = useState(total);
  const [showFilter, setShowFilter] = useState(false);
  const [renderSreach, setRenderSreach] = useState(false);
  const [isEdit, setIsEdit] = useState({
    record: {},
    check: false,
  });
  const [priceData, setPriceData] = useState(0);

  useEffect(() => {
    const today = new Date();
    const todayDate = new Date().toLocaleDateString('sv-SE');
    today.setDate(today.getDate() - 7);
    const date = new Date(today).toLocaleDateString('sv-SE');
    setStartDate(moment(date).format('DD-MM-YYYY') + ' ' + '00:00:00');
    setEndDate(moment(todayDate).format('DD-MM-YYYY') + ' ' + '23:59:59');
    setStartDateView(moment(date).format('DD-MM-YYYY'));
    setEndDateView(moment(todayDate).format('DD-MM-YYYY'));
  }, []);

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      if (
        nameStatus === 'Đang chờ' ||
        nameStatus === 'Đang phục vụ' ||
        nameStatus === 'Đã phục vụ' ||
        nameStatus === 'Đã hủy'
      ) {
        if (supplierId !== '') {
          getDataBookingStatus();
        }
      } else {
        if (supplierId !== '') {
          getDataBooking();
        }
      }
      getTotalStatus();
    }
    setRenderStatus(false);
    setRenderDel(false);
    setSelectedKeys([]);
    setSelectedRows([]);
  }, [
    supplierId,
    page,
    size,
    renderDel === true,
    renderStatus === true,
    nameStatus,
    renderSreach,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      getTotalStatus();
    }, POOLING_TIME_DEFAULT);

    return () => {
        clearInterval(interval);
    };
}, [POOLING_TIME_DEFAULT]);

  useEffect(() => {
    const interval = setInterval(() => {
      getTotalStatus();
    }, POOLING_TIME_DEFAULT);

    return () => {
      clearInterval(interval);
    };
  }, [POOLING_TIME_DEFAULT]);

  const getDataBooking = () => {
    axios
      .get(
        `/booking/get?page=${page}&status=Đang chờ&size=${size}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        setDataTable(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const getDataBookingStatus = () => {
    axios
      .get(
        `/booking/get?page=${page}&size=${size}&status=${nameStatus}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        setDataTable(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const getTotalStatus = () => {
    axios
      .get(`/booking/count?supplier=${supplierId}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => {
        setTotalStatus(response?.data?.data);
      });
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      render: (t, r, i) => i + 1,
      width: 100,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
      render: (value) => (
        <Tooltip placement="topLeft" title={moment(value).format('DD/MM/YYYY - HH:mm')}>
          <span> {moment(value).format('DD/MM/YYYY - HH:mm')}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Trạng thái Booking',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <span
          id="check-color"
          style={{
            borderRadius: 5,
            color:
              value === 'Đang chờ'
                ? '#0693E3'
                : value === 'Đang phục vụ'
                ? '#00d084'
                : value === 'Đã phục vụ'
                ? '#607D8B'
                : '#f47373',
            padding: 4,
            fontWeight: 'bold',
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: 'Mã Booking',
      dataIndex: 'bookingCode',
      key: 'bookingCode',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'totalMoney',
      key: 'totalMoney',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value) + 'VND'}>
          <b> {FormatMoney(value)} VND</b>
        </Tooltip>
      ),
    },
    {
      title: 'Thành viên',
      dataIndex: 'customerName',
      key: 'customerName',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'SĐT Thành viên',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'innerNote',
      key: 'innerNote',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: 'Chức năng',
      key: 'action',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        // console.log("du lieu", record?.bookingDetailResponses.map(e => e.status !== 'Đang chờ' || e.status !== '')),
        <Space>
          {/* {record?.status === 'Đang chờ'
            ?
            <Popover content={<h4> Sửa Booking</h4>}>
              <EditOutlined
                style={{
                  background: "rgb(55, 181, 157)",
                  borderRadius: 3,
                  fontSize: "12px",
                  color: "white",
                  padding: 5,
                  border: "1px solid rgb(55, 181, 157)",
                }}
                onClick={() => onShowUpd(record)}
              />
            </Popover>
            : null
          } */}
        </Space>
      ),
    },
  ];

  const columnsChildren = (bookingId) => {
    return [
      {
        title: 'Tên dịch vụ',
        dataIndex: 'serviceUserName',
        key: 'serviceUserName',
        ellipsis: {
          showTitle: false,
        },
        width: 150,
        render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            <b style={{ color: '#697689' }}>{value}</b>
          </Tooltip>
        ),
      },

      {
        title: 'Trạng thái dịch vụ',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <span
            id="check-color"
            style={{
              borderRadius: 5,
              color:
                value === 'Đang chờ'
                  ? '#0693E3'
                  : value === 'Đang phục vụ'
                  ? '#00d084'
                  : value === 'Đã phục vụ'
                  ? '#607D8B'
                  : '#f47373',
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            {value}
          </span>
        ),
      },
      {
        title: 'Thời gian cập nhật',
        dataIndex: 'modifiedDate',
        key: 'modifiedDate',
        ellipsis: {
          showTitle: false,
        },
        width: '10%',
        responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
        render: (value) => (
          <Tooltip placement="topLeft" title={moment(value).format('DD/MM/YYYY - HH:mm')}>
            <span> {moment(value).format('DD/MM/YYYY - HH:mm')}</span>
          </Tooltip>
        ),
      },
      // {
      //   title: 'Giá ban đầu',
      //   dataIndex: 'priceDefault',
      //   key: 'priceDefault',
      //   ellipsis: {
      //     showTitle: false,
      //   },
      //   width: '20%',
      //   render: (text, record, index) => (
      //     <div>
      //       {renderPrice(
      //         record?.type,
      //         record?.price,
      //         record?.minPrice,
      //         record?.maxPrice,
      //         record?.discount
      //       )}
      //     </div>
      //   ),
      // },
      {
        title: 'Giá dịch vụ',
        dataIndex: 'price',
        key: 'price',
        ellipsis: {
          showTitle: false,
        },
        width: '20%',
        render: (text, record, index) => (
          <div style={{display:'flex',gap:'10px',alignItems:"center"}}>
            <div>{isEdit.check && isEdit.record.id == record.id}</div>
            {
              !(isEdit.check && isEdit.record.id == record.id) ? `${record.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') :
              <InputNumber
                disabled={!(isEdit.check && isEdit.record.id == record.id)}
                defaultValue={record.price}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                onChange={handleChangePrice}
                style={{minWidth:"150px"}}
              />
            }
            
            <span>VND</span>
          </div>
        ),
      },
      {
        title: 'Nhân viên',
        dataIndex: 'employeeName',
        key: 'employeeName',
        ellipsis: {
          showTitle: false,
        },
        width: 200,
        render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            <b style={{ color: '#697689' }}>{value}</b>
          </Tooltip>
        ),
      },
      {
        title: 'Thời gian dự kiến',
        dataIndex: 'intendTime',
        key: 'intendTime',
        ellipsis: {
          showTitle: false,
        },
        responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
        width: '15%',
        render: (value) => (
          <Tooltip placement="topLeft" title={moment(value).format('DD-MM-YYYY HH:mm:ss')}>
            <span> {moment(value).format('DD-MM-YYYY HH:mm:ss')}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Thời gian thực tế',
        dataIndex: 'realTime',
        key: 'realTime',
        ellipsis: {
          showTitle: false,
        },
        responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
        width: '15%',
        render: (value) =>
          value !== null ? (
            <Tooltip placement="topLeft" title={moment(value).format('DD-MM-YYYY HH:mm:ss')}>
              <span> {moment(value).format('DD-MM-YYYY HH:mm:ss')}</span>
            </Tooltip>
          ) : (
            <span>0</span>
          ),
      },
      {
        title:  (nameStatus === 'Đang chờ' || nameStatus === 'Đang phục vụ') ?  'Chỉnh sửa' : '',
        dataIndex: 'edit',
        key: 'edit',
        ellipsis: {
          showTitle: false,
        },
        responsive: ['lg', 'xs', 'sm', 'md', 'xl'],
        width: '15%',
        render: (text, record, index) => (
          <div>
            {record.status != 'Đã phục vụ' &&
              record.status != 'Đã hủy' &&
              !(isEdit.check && isEdit.record.id == record.id) && (
                <Popover content={<h4> Sửa thông tin</h4>}>
                  <EditOutlined
                    style={{
                      background: 'rgb(55, 181, 157)',
                      borderRadius: 3,
                      fontSize: '12px',
                      color: 'white',
                      padding: 5,
                      border: '1px solid rgb(55, 181, 157)',
                    }}
                    onClick={() => onShowUpd(record)}
                  />
                </Popover>
              )}
            {isEdit.check && isEdit.record.id == record.id && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Popover content={<h4> Sửa thông tin</h4>}>
                  <SaveOutlined
                    style={{
                      background: '#2a7aec',
                      borderRadius: 3,
                      fontSize: '12px',
                      color: 'white',
                      padding: 5,
                      border: '1px solid #2a7aec',
                    }}
                    onClick={() => onSaveData(record, bookingId)}
                  />
                </Popover>
                <Popover content={<h4> Huỷ</h4>}>
                  <CloseCircleOutlined
                    style={{
                      background: 'rgb(250 45 80)',
                      borderRadius: 3,
                      fontSize: '12px',
                      color: 'white',
                      padding: 5,
                      border: '1px solid rgb(250 45 80)',
                    }}
                    onClick={() => {
                      setPriceData(record?.price);
                      setIsEdit({
                        record: {},
                        check: false,
                      });
                    }}
                  />
                </Popover>
              </div>
            )}
          </div>
        ),
      },
    ];
  };

  // const handleChangePrice = useCallback((e) => {
  //   setPriceData(e.target.value);
  // }, []);

  const handleChangePrice = (e) => {
    setPriceData(e);
  };

  const onSaveData = (record, bookingId) => {
    const body = {
      bookingId: bookingId,
      id: record.id,
      price: priceData,
    };
    dispatch(
      updateBookingDetail(body, {
        onSuccess: () => {
          notification.success({
            message: `Thêm booking thành công`,
          });
          getDataBooking();
          setIsEdit({
            record: {},
            check: false,
          });
        },
        onError: () => {
          notification.error({
            message: `Thêm booking thất bại`,
          });
        },
      })
    );
  };

  const onShowIns = async () => {
    history.push('/insBooking');
  };

  const onShowUpd = (record) => {
    setIsEdit({
      record,
      check: true,
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onChangeRowSelectedData = (key, rows) => {
    setSelectedRows(rows);
    setSelectedKeys(key);
  };

  const onChangeRowSelectedDataBig = (key, rows) => {
    setSelectedRowsBig(rows);
    setSelectedKeysBig(key);
    rows.forEach((row) => {
      setSelectedRows(row.bookingDetailResponses);
      setSelectedKeys(row.bookingDetailResponses.map((item) => item.id));
    });
  };

  const onChangeStatusHuy = () => {
    if (selectedKeys.toString() !== '') {
      if (selectedRows[0]?.status !== 'Đang chờ') {
        notification.warning({
          message: `Dịch vụ không thể hủy`,
        });
      } else {
        dispatch(
          changeStatus('Đã hủy', selectedKeys.toString(), {
            onSuccess: () => {
              notification.success({
                message: `Hủy dịch vụ thành công`,
              });
              setRenderStatus(true);
            },
            onError: () => {
              notification.error({
                message: `Hủy thất bại`,
              });
            },
          })
        );
      }
    } else {
      notification.warning({
        message: `Chọn Chi tiết Booking`,
      });
    }
  };

  const onChangeStatusDC = () => {
    if (selectedKeys.toString() !== '') {
      if (selectedRows[0]?.status !== 'Đang chờ') {
        notification.warning({
          message: `Dịch vụ không thể phục vụ`,
        });
      } else {
        dispatch(
          changeStatus('Đang phục vụ', selectedKeys.toString(), {
            onSuccess: () => {
              notification.success({
                message: `Chuyển trạng thái dịch vụ thành công`,
              });
              setRenderStatus(true);
            },
            onError: () => {
              notification.error({
                message: `Thất bại`,
              });
            },
          })
        );
      }
    } else {
      notification.warning({
        message: `Chọn Chi tiết Booking`,
      });
    }
  };

  const onChangeStatusDPV = () => {
    if (selectedKeys.toString() !== '') {
      if (selectedRows[0]?.status !== 'Đang phục vụ') {
        notification.warning({
          message: `Booking chưa được xác nhận`,
        });
      } else {
        dispatch(
          changeStatus('Đã phục vụ', selectedKeys.toString(), {
            onSuccess: () => {
              notification.success({
                message: `Chuyển trạng thái dịch vụ thành công`,
              });
              setRenderStatus(true);
            },
            onError: () => {
              notification.error({
                message: `Thất bại`,
              });
            },
          })
        );
      }
    } else {
      notification.warning({
        message: `Chọn Chi tiết Booking`,
      });
    }
  };

  const onChangeStatus = (e) => {
    setNameStatus(e);
  };

  // const onChangeTime = (e, date) => {
  //   if (date[0] !== '') {
  //     setStartDate(date[0] + " " + '00:00:00')
  //     setEndDate(date[1] + " " + '23:59:59')
  //   } else {
  //     const today = new Date();
  //     const todayDate = new Date().toLocaleDateString("sv-SE");
  //     today.setDate(today.getDate() - 7);
  //     const date = new Date(today).toLocaleDateString("sv-SE");
  //     setStartDate(moment(date).format("DD-MM-YYYY") + " " + '00:00:00')
  //     setEndDate(moment(todayDate).format("DD-MM-YYYY") + " " + '23:59:59')
  //     setStartDateView(moment(date).format("DD-MM-YYYY"))
  //     setEndDateView(moment(todayDate).format("DD-MM-YYYY"))
  //   }

  // }

  const onChangeTimeStart = (e, date) => {
    if (date !== '') {
      setStartDate(date + ' ' + '00:00:00');
    } else {
      const today = new Date();
      today.setDate(today.getDate() - 7);
      const date = new Date(today).toLocaleDateString('sv-SE');
      setStartDate(moment(date).format('DD-MM-YYYY') + ' ' + '00:00:00');
      setStartDateView(moment(date).format('DD-MM-YYYY'));
    }
  };

  const onChangeTimeEnd = (e, date) => {
    if (date !== '') {
      setEndDate(date + ' ' + '23:59:59');
    } else {
      const today = new Date();
      const todayDate = new Date().toLocaleDateString('sv-SE');
      today.setDate(today.getDate() - 7);
      const date = new Date(today).toLocaleDateString('sv-SE');
      setEndDate(moment(todayDate).format('DD-MM-YYYY') + ' ' + '23:59:59');
      setEndDateView(moment(todayDate).format('DD-MM-YYYY'));
    }
  };

  const onInsBill = () => {
    if (selectedKeysBig.length > 0) {
      if (selectedRowsBig[0].status === 'Đã phục vụ') {
        dispatch(
          insBill(selectedKeysBig.toString(), {
            onSuccess: () => {
              notification.success({
                message: `Xuất hóa đơn thành công`,
              });
              setSelectedKeysBig([]);
              setSelectedRowsBig([]);
            },
            onError: () => {
              notification.error({
                message: `Xuất hóa đơn thất bại`,
              });
            },
          })
        );
      } else {
        notification.warning({
          message: `Booking chưa hoàn thành không thể xuất hóa đơn`,
        });
      }
    } else {
      notification.warning({
        message: `Chọn Booking đê xuất hóa đơn`,
      });
    }
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const onSearch = () => {
    if (query !== '') {
      const body = {
        query: query,
        supplierId: supplierId,
        startDate: startDate,
        endDate: endDate,
      };
      axios.post(`/booking/search?page=0&size=10`, body).then((response) => {
        setDataTable(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
    } else {
      setRenderSreach(!renderSreach);
    }
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const onReloadSearch = () => {
    setQuery('');
    const today = new Date();
    const todayDate = new Date().toLocaleDateString('sv-SE');
    today.setDate(today.getDate() - 7);
    const date = new Date(today).toLocaleDateString('sv-SE');
    setStartDate(moment(date).format('DD-MM-YYYY') + ' ' + '00:00:00');
    setEndDate(moment(todayDate).format('DD-MM-YYYY') + ' ' + '23:59:59');
    setStartDateView(moment(date).format('DD-MM-YYYY'));
    setEndDateView(moment(todayDate).format('DD-MM-YYYY'));
    setRenderSreach(!renderSreach);
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/calendarBooking'}>
                {' '}
                <ScheduleOutlined /> Lịch Booking
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Danh sách Booking</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Row span={24}>
            <Col span={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
            <Col span={12}>
              <Button
                style={{
                  backgroundColor: 'rgb(55, 181, 157)',
                  border: 'rgb(55, 181, 157)',
                  float: 'right',
                }}
                type="primary"
                onClick={onShowIns}
              >
                <PlusOutlined />
                Thêm
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <FilterTime
                      valueStart={dayjs(startDate, dateFormat)}
                      valueEnd={dayjs(endDate, dateFormat)}
                      format={dateFormat}
                      onChangeTimeStart={onChangeTimeStart}
                      onChangeTimeEnd={onChangeTimeEnd}
                      styleRangePicker={{ width: '60%' }}
                    />
                  </Col>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach
                      styleInput={{ width: '80%' }}
                      value={query}
                      placeholder="Tìm kiếm"
                      onChange={onChangeQuery}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button
                    style={{ backgroundColor: '#406FBF', marginBottom: 5 }}
                    type="primary"
                    onClick={onSearch}
                  >
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                    onClick={onReloadSearch}
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24}>
        <Col span={24}>
          <TabStatus
            onChangeStatusDC={onChangeStatusDC}
            onChangeStatusHuy={onChangeStatusHuy}
            onChangeStatusDPV={onChangeStatusDPV}
            onChangeStatus={(e) => onChangeStatus(e)}
            onInsBill={onInsBill}
            totalStatus={totalStatus}
          />
          <Table
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <b>Chi tiết mã Booking: {record?.bookingCode}</b>
                  <div
                    style={{
                      borderRadius: 5,
                      border: '1px solid #CCCCCC',
                      padding: 15,
                    }}
                  >
                    <Table
                      rowKey={(record) => record.id}
                      rowSelection={{
                        selectedRows: selectedRows,
                        selectedRowKeys: selectedKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                          onChangeRowSelectedData(selectedRowKeys, selectedRows);
                        },
                      }}
                      columns={columnsChildren(record.id)}
                      dataSource={record?.bookingDetailResponses}
                      size="small"
                      pagination={false}
                    />
                  </div>
                </>
              ),
            }}
            style={{ paddingTop: '15px' }}
            rowKey={(record) => record.id}
            rowSelection={{
              selectedRows: selectedRowsBig,
              selectedRowKeys: selectedKeysBig,
              onChange: (selectedRowKeys, selectedRows) => {
                onChangeRowSelectedDataBig(selectedRowKeys, selectedRows);
              },
            }}
            columns={columns}
            dataSource={dataTable}
            size="small"
            pagination={{
              // position: 'none',
              onChange: (page) => {
                setPage(page - 1);
              },
              pageSize: size,
              total: totalPage,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
            }}
            scroll={{ x: 'calc(450px + 50%)' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Booking;
