import { PlusOutlined, HomeOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Switch,
  Upload,
  message,
  notification,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CKEditor5 from "../../components/Ckeditor";
import {
  REACT_APP_API_KEY,
  REACT_APP_API_KEY_IMAGE,
} from "../../constant/data";
import { addBlog, getBlog } from "../../reducers/blogReducer";
import "./Blog.css";
import { Link } from "react-router-dom";

const JWT_HEADER = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Cache: "no-cache",
});
export default function InsBlog() {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = window.location.href.split("/update-blog/")[1];

  const { TextArea } = Input;
  const supplierId = localStorage.getItem("supplierId");
  const [images, setImages] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (!!id) {
      dispatch(
        getBlog(id, {
          onSuccess: (res) => {
            form.setFieldsValue({ ...res.data?.data?.data });
            setImages(res.data?.data?.data?.icon);
          },
        })
      );
    }
  }, [id]);

  const onIns = (value) => {
    const body = {
      ...value,
      active:!id ? 1 : !!value?.active ? 1 : 0  ,
      supplierId: supplierId,
      icon: images,
      numberView: 100,
      id: !!id ? id : "",
    };
    dispatch(
      addBlog(body, {
        onSuccess: (message) => {
          notification.success({
            message: `Thêm bài viết thành công`,
          });
          history.push("/blogs");
        },
        onError: (message) => {
          notification.error({
            message: `Thêm bài viết thất bại`,
          });
        },
      })
    );
  };

  const API_URL = `${REACT_APP_API_KEY}`;

  const UPLOAD_ENDPOINT = `/auth/test-public/upload-file`;

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${API_URL}${res.image}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  const handleChangeLogo = (info) => {
    const { status } = info.file;
    if (status === "done") {
      setImages(info?.file?.response?.data || "");
      if (info?.file?.response?.status == "500") {
        message.error("Định dạng ảnh không phù hợp!")
      }
    } else if (status === "error") {
      message.error("Định dạng ảnh không phù hợp!")
    }
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <b>
              <Link style={{ color: "#808080" }} to={"/"}>
                {" "}
                <HomeOutlined />{" "}
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: "black" }}>
            <b>{!!id ? "Chỉnh sửa thông tin bài viết" : "Thêm mới bài viết"}</b>
          </div>
        </Breadcrumb>
      </Row>
      <Row span={24} style={{ marginTop: 10 }}>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={22} lg={18} xl={18} xxl={18}>
          <Form
            form={form}
            id="updServiceVb"
            onFinish={onIns}
            autoComplete="off"
            labelCol={{ xs: 24, sm: 11, md: 8, lg: 6, xl: 6, xxl: 4 }}
            wrapperCol={{ xs: 24, sm: 13, md: 16, lg: 18, xl: 18, xxl: 20 }}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Hình ảnh" style={{ fontWeight: "500" }}>
                  <ImgCrop rotate>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${API_URL}/auth/test-public/upload-file`}
                      headers={JWT_HEADER}
                      method="POST"
                      onChange={handleChangeLogo}
                    >
                      {images ? (
                        <Image
                          className="imgLogo"
                          src={`${REACT_APP_API_KEY_IMAGE}${images}`}
                          alt="avatar"
                          preview={false}
                        />
                      ) : (
                        <div style={{ fontWeight: "bold" }}>
                          <PlusOutlined />
                          <div style={{ marginTop: 4, fontWeight: "bold" }}>
                            Upload hình ảnh
                          </div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>

                {
                  !!id &&
                  <Form.Item
                    style={{ fontWeight: "500" }}
                    label="Trạng thái"
                    name="active"
                    rules={[{ required: true }]}
                  >
                    <Switch
                    />
                  </Form.Item>
                }

                <Form.Item
                  style={{ fontWeight: "500" }}
                  label="Tiêu đề"
                  name="title"
                  rules={[{ required: true, message: "Nhập tên dịch vụ" }]}
                >
                  <TextArea autoSize placeholder="Tên dịch vụ" />
                </Form.Item>

                <Form.Item
                  style={{ fontWeight: "500" }}
                  label="Mô tả"
                  name="description"
                  rules={[{ required: true, message: "Nhập mô tả" }]}
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="Mô tả"
                  />
                </Form.Item>

                <Form.Item
                  style={{ fontWeight: "500" }}
                  label="Chi tiết"
                  name="detail"
                  rules={[{ required: true, message: "Nhập mô tả" }]}
                >
                  <CKEditor5
                    required={true}
                    title={"Nội dung trả lời"}
                    uniqueKey={"qnaAswers"}
                    name="qnaAswers"
                    link={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                    linkViewFile={`${REACT_APP_API_KEY}/auth/test-public/view?fileName=`}
                    textIndent={false}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  style={{
                    marginLeft: "6px",
                    backgroundColor: "rgb(55, 181, 157)",
                    border: "rgb(55, 181, 157)",
                    float: "right",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Xác nhận
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={1} lg={4} xl={4} xxl={4} />
      </Row>
    </>
  );
}
