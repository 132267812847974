import { store } from '../App';
import { REACT_APP_API_KEY } from '../constant/data';
import { reset } from '../reducers/authReducer';

import { create } from 'axios';

const api = create({
  baseURL: REACT_APP_API_KEY,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  },
  withCredentials: false,
  timeout: 30000,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  return { ...config, headers: { ...config.headers, Authorization: `Bearer ${token}` } };
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(reset());
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  }
);

export default api;
