import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Input, Modal, notification } from 'antd';

import { addGroup } from '../../reducers/grService';

export default function InsGroup(props) {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { show, handleCancel } = props;
  const [form] = Form.useForm();

  const onIns = (values) => {
    const body = {
      name: values?.name,
      status: true,
      description: values?.description,
    };
    dispatch(
      addGroup(body, {
        onSuccess: (res) => {
          if(res?.data?.data?.status?.statusCode != 200){
            notification.error({
              message: res?.data?.data?.status?.message,
            });
          }else notification.success({
            message: `Thêm nhóm dịch vụ thành công`,
          });
          handleCancelIns();
        },
        onError: (message) => {
          notification.error({
            message: `Thêm nhóm dịch vụ thất bại`,
          });
        },
      })
    );
  };

  const handleCancelIns = () => {
    handleCancel();
    form.setFieldsValue({
      name: '',
      description: '',
    });
  };

  return (
    <Modal
      title={`THÊM MỚI NHÓM DỊCH VỤ`}
      open={show}
      onCancel={handleCancel}
      okButtonProps={{
        form: 'insGroupService',
        key: 'submit',
        htmlType: 'submit',
      }}
    >
      <Form
        form={form}
        id="insGroupService"
        onFinish={onIns}
        autoComplete="off"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Tên nhóm dịch vụ "
          name="name"
          rules={[{ required: true, message: 'Nhập tên nhóm  dịch vụ' }]}
        >
          <TextArea autoSize placeholder="Tên nhóm  dịch vụ" />
        </Form.Item>
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Mô tả  "
          name="description"
          rules={[{ required: true, message: 'Nhập mô tả' }]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="Mô tả" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
