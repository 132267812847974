import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Breadcrumb, Col, Dropdown, List, Menu, Radio, Row } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import logoVBNOW from '../../assets/logovuong.png';
import { changeStatusNoti } from '../../reducers/notiReducer';
import axios from '../../services/Api';
import ModalBill from '../Bill/ModalBill';

import './BadgeNote.css';

import moment from 'moment';

export default function ViewAllNoti() {
  const dispatch = useDispatch();
  const history = useHistory();
  const supplierId = localStorage.getItem('supplierId');
  
  const [type, setType] = useState('all');
  const [status, setStatus] = useState(1);
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [pageScroll, setPageScroll] = useState(1);
  const [sizeScroll, setSizeScroll] = useState(10);
  const [total, setTotal] = useState(0);
  const [renderNoti, setRenderNoti] = useState(false);
  const [showView, setShowView] = useState({ show: false, bookingId: null });
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setPage(0);
    setPageScroll(1);
    setSize(10);
    setSizeScroll(10);
    setRenderNoti(false);
    getDataNoti();
  }, [renderNoti === true, type, status]);

  const getDataNoti = () => {
    if (type === 'all') {
      if (status === 3) {
        var body = { supplierId: supplierId };
      } else {
        var body = { status: status, supplierId: supplierId };
      }
    } else {
      if (status === 3) {
        var body = { type: type, supplierId: supplierId };
      } else {
        var body = { type: type, status: status, supplierId: supplierId};
      }
    }
    axios.post(`/history-notification/get?page=${page}&size=${size}`, body).then((response) => {
      let newDatas = response.data.data;
      setData(newDatas);
      setTotal(response?.data?.count);
      setHasMore(true);
    });
  };
  const getDataNotiInfiniteScroll = () => {
    if (type === 'all') {
      if (status === 3) {
        var body = { supplierId: supplierId };
      } else {
        var body = { status: status, supplierId: supplierId };
      }
    } else {
      if (status === 3) {
        var body = { type: type, supplierId: supplierId};
      } else {
        var body = { type: type, status: status, supplierId: supplierId };
      }
    }
    if (data.length != total) {
      axios
        .post(`/history-notification/get?page=${pageScroll}&size=${sizeScroll}`, body)
        .then((response) => {
          let newDatas = response.data.data;
          setData(data.concat([...newDatas]));
          setPageScroll((prev) => prev + 1);
        });
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };

  const onViewFalse = (id, data) => {
    const status = false;
    dispatch(
      changeStatusNoti(status, id, {
        onSuccess: (message) => {
          setRenderNoti(true);
        },
        onError: (message) => {},
      })
    );
    if (data?.type === 'booking') {
      setShowView({ show: true, bookingId: data?.id });
    } else {
      console.log('modal khác');
    }
  };

  const onCancelShowView = () => {
    setShowView({ show: false, bookingId: null });
  };

  const onChangeType = (e) => {
    setType(e.target.value);
  };

  const onViewAllNoti = () => {
    history.replace('./viewAllNoti');
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="Unread" onClick={() => setStatus(1)}>
        <div style={{ paddingLeft: '5px', color: '#333333' }}>
          <b>Chưa đọc</b>
        </div>
      </Menu.Item>
      <Menu.Item key="allRead" onClick={() => setStatus(3)}>
        <div style={{ paddingLeft: '5px', color: '#333333' }}>
          <b>Tất cả</b>
        </div>
      </Menu.Item>
      {/* <Menu.Item key="viewAll" onClick={() => onViewAllNoti()}>
                <div style={{ paddingLeft: "5px", color: '#333333' }}><b>Xem tất cả</b></div>
            </Menu.Item> */}
    </Menu>
  );

  return (
    <>
      <Row span={24}>
        <Col span={6}>
          <Row span={24}>
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <b>
                    <Link style={{ color: '#808080' }} to={'/'}>
                      {' '}
                      <HomeOutlined />{' '}
                    </Link>
                  </b>
                </Breadcrumb.Item>
                <div style={{ color: 'black' }}>
                  <b>Danh sách thông báo</b>
                </div>
              </Breadcrumb>
            </Col>
          </Row>
        </Col>
        <Col span={12} style={{ paddingTop: '10px' }}>
          <div
            style={{
              backgroundColor: '#E0E8EC',
              width: '90%',
              border: '2px solid #E0E8EC',
              borderRadius: '8px',
            }}
          >
            <div className="list-infinite-container">
              <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                useWindow={false}
                hasMore={hasMore}
                loadMore={getDataNotiInfiniteScroll}
              >
                <Row span={24}>
                  <Col span={24}>
                    <div
                      style={{
                        backgroundColor: '#BDD9EB',
                        height: '60px',
                        border: '2px solid #BDD9EB',
                        borderRadius: '5px',
                      }}
                    >
                      <div style={{ height: '30px' }}>
                        <Row span={24}>
                          <Col span={19}>
                            <div
                              style={{
                                marginLeft: '10px',
                                paddingTop: '12px',
                              }}
                            >
                              <Radio.Group
                                defaultValue="all"
                                buttonStyle="solid"
                                onChange={onChangeType}
                              >
                                <Radio.Button value="all">Tất cả</Radio.Button>
                                <Radio.Button value="booking">Booking</Radio.Button>
                                <Radio.Button value="feedback">Feedback</Radio.Button>
                                <Radio.Button value="other">Khác</Radio.Button>
                              </Radio.Group>
                            </div>
                          </Col>
                          <Col span={4}>
                            <div style={{ float: 'right' }}>
                              <Dropdown trigger={['click']} overlay={dropdownMenu}>
                                <div
                                  style={{
                                    display: 'inline-block',
                                    cursor: 'pointer',
                                    paddingTop: '12px',
                                  }}
                                >
                                  <svg
                                    t="1670984147773"
                                    className="icon"
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    p-id="4087"
                                    width="22"
                                    height="22"
                                  >
                                    <path
                                      d="M896 640c-70.656 0-128-57.344-128-128s57.344-128 128-128 128 57.344 128 128-57.344 128-128 128z m-384 0c-70.656 0-128-57.344-128-128s57.344-128 128-128 128 57.344 128 128-57.344 128-128 128zM128 640c-70.656 0-128-57.344-128-128s57.344-128 128-128 128 57.344 128 128-57.344 128-128 128z"
                                      fill=""
                                      p-id="4088"
                                    />
                                  </svg>
                                </div>
                              </Dropdown>
                            </div>
                          </Col>
                          <Col span={1} />
                        </Row>
                        <br />
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="list-noti">
                      <List
                        style={{
                          marginTop: 10,
                          cursor: 'pointer',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                        dataSource={data}
                        renderItem={(item) => (
                          <div onClick={() => onViewFalse(item.id, item)}>
                            <List.Item>
                              <List.Item.Meta
                                avatar={
                                  <img className="logo-photo" src={logoVBNOW} alt="conversation" />
                                }
                                title={
                                  <div
                                    className={
                                      item.status == 1
                                        ? 'conversation-title-unread2'
                                        : 'conversation-title2'
                                    }
                                  >
                                    {item?.title}
                                    <span className="chat-time2">
                                      {moment(item?.createdDate).format('DD-MM-YYYY')}
                                    </span>
                                  </div>
                                }
                                description={
                                  <div>
                                    <div
                                      className={
                                        item.status == 1 ? 'fix-line-css-unread2' : 'fix-line-css2'
                                      }
                                    >
                                      {item?.content}
                                    </div>
                                    {item?.status == 1 ? (
                                      <div
                                        style={{
                                          width: '10%',
                                          float: 'right',
                                        }}
                                      >
                                        <svg
                                          t="1670903881489"
                                          className="icon"
                                          viewBox="0 0 1064 1024"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          p-id="3450"
                                          width="12"
                                          height="12"
                                        >
                                          <path
                                            d="M512 0c282.781538 0 512 229.218462 512 512S794.781538 1024 512 1024s-512-229.218462-512-512S229.218462 0 512 0z"
                                            p-id="3451"
                                            fill="#37B59D"
                                          />
                                        </svg>
                                      </div>
                                    ) : null}
                                  </div>
                                }
                              />
                            </List.Item>
                          </div>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </InfiniteScroll>
            </div>
          </div>
        </Col>
        <Col span={6} />
      </Row>
      {/* <ModalBill
        show={showView.show}
        bookingId={showView.bookingId}
        handleCancel={onCancelShowView}
      /> */}
    </>
  );
}
