import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
  message
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import logo from '../../../assets/logochuan.png';
import signinbg from "../../../assets/mo-hinh-kinh-doanh.jpg";
import { login } from '../../../reducers/authReducer';
import "./main.css";

import {
  DribbbleOutlined,
  GithubOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

// import './Login.css';
const Login = () => {
const history = useHistory();
const dispatch = useDispatch();
const [form] = Form.useForm();
const token = useSelector((store) => store.authReducer.token);
const checkSupplier = useSelector((store) => store.authReducer.checkSupplier);



const onLogin = ({ username, password }) => {
  const body = {
    username,
    password,
  };
  dispatch(
    login(body, {
      onSuccess: (res) => {
        console.log(res)
        history.push('/');
      },
      onError: () => {
        message.error('Sai tài khoản hoặc mật khẩu.');
      },
    })
  );
};

  return token ? (
    checkSupplier === true ? (
      <Redirect to="/" />
    ) : (
      <Redirect to="/createSupplier" />
    )
  ) : (
    <Layout className="layout-default layout-signin">
    <Content className="signin">
      <Row gutter={[24, 0]} justify="space-around">
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 6, offset: 2 }}
          md={{ span: 12 }}
        >
      
            <div>
              <img width="60%" src={logo} alt="logo" />
            </div>

          <Title className="mb-15">Đăng nhập</Title>
          <Title className="font-regular text-muted" level={5}>
          Đăng nhập vào Cộng đồng
          </Title>
          <Form
            form={form}
            id="insUser-form"
            name="normal_Resgiter"
            onFinish={onLogin}
            autoComplete="off"
          >
            <Form.Item
              style={{ fontWeight: "bold" }}
              name="username"
              rules={[{ required: true, message: "Nhập tên đăng nhập" }]}
            >
              <Input
                style={{
         
                  borderRadius: 6,
                  fontSize: "1.1em",
                }}
                placeholder="Tên đăng nhập"
              />
            </Form.Item>

            <Form.Item
              style={{ fontWeight: "bold" }}
              name="password"
              rules={[{ required: true, message: "Nhập mật khẩu" }]}
            >
              <Input.Password
                style={{
      
                  borderRadius: 6,
                  fontSize: "1.1em",
                }}
                placeholder="Mật khẩu"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: 6,
                  backgroundColor: "#1877f2",
                  border: "none",
                  fontWeight: "bold",
                  fontSize: "1.2em",
                }}
              >
                Đăng nhập
              </Button>
            </Form.Item>
            <hr />
            <Link to="/register">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '100%',
                      height: '50px',
                      borderRadius: 6,
                      backgroundColor: '#37D67A',
                      border: 'none',
                      fontWeight: 'bold',
                      fontSize: '1.2em',
                    }}
                  >
                    Tạo tài khoản
                  </Button>
                </Link>
          </Form>
        </Col>
        <Col
          className="sign-img"
          style={{ padding: 12 }}
          xs={{ span: 24 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
        >
          <img src={signinbg} alt="" />
        </Col>
      </Row>
    </Content>
    <Footer>
      <Menu mode="horizontal">
        <Menu.Item>Company</Menu.Item>
        <Menu.Item>About Us</Menu.Item>
        <Menu.Item>Teams</Menu.Item>
        <Menu.Item>Products</Menu.Item>
        <Menu.Item>Blogs</Menu.Item>
        <Menu.Item>Pricing</Menu.Item>
      </Menu>
      <Menu mode="horizontal" className="menu-nav-social">
        <Menu.Item>
          <Link to="#">{<DribbbleOutlined />}</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="#">{<TwitterOutlined />}</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="#">{<InstagramOutlined />}</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="#">
            <svg
              width="18"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" />
            </svg>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="#">{<GithubOutlined />}</Link>
        </Menu.Item>
      </Menu>
      <p className="copyright">
        {" "}
        Copyright © 2024 Muse by <a href="#pablo">VVECO</a>.{" "}
      </p>
    </Footer>
  </Layout>
  );
};

export default Login;