import { Button, Modal } from "antd";  // Keeping only Modal from Ant Design
import "./Invoice.css";  // Custom CSS for styling
import { useEffect, useRef, useState } from "react";
import axios from '../../services/Api';
import moment from "moment";
import formatMoney from '../../components/ConvertMoney';
import printJS from 'print-js';  // Import print-js for printing
import { REACT_APP_API_KEY_IMAGE } from "../../constant/data";
import logo from '../../assets/logowhite.png';

const Invoice = ({ show, id, handleCancel }) => {
    const componentRef = useRef(null);
    const [images, setImages] = useState('');

    // Print functionality using print-js
    const handlePrint = () => {
        printJS({
            printable: componentRef.current,
            type: 'html',
            scanStyles: true,
            css: ['./Invoice.css'], // External CSS file (optional)
            targetStyles: ['*'], // This includes all styles from the document
            onPrintDialogClose: () => {},
        });
    };

    const FormatMoney = (value) => {
        let data = parseInt(value);
        return formatMoney(data);
    };

    const [dataBill, setDataBill] = useState([]);
    const [money, setMoney] = useState(0);
    const [createdDate, setCreatedDate] = useState();
    const [bookingCode, setBookingCode] = useState('');
    const [discountMoney, setDiscountMoney] = useState(0);
    const [discountMoneyNow, setDiscountMoneyNow] = useState(0);
    const [totalMoney, setTotalMoney] = useState(0);
    const [formCustomer, setFormCustomer] = useState({
        customerName: '',
        phone: '',
        mail: ''
    });

    useEffect(() => {
        if (id !== undefined) {
            getDataBillById();
        }
    }, [id]);

    const getDataBillById = () => {
        axios.get(`/bill/get-id?id=${id}`).then((response) => {
            const data = response.data.data;
            setDataBill(data.billDetails);
            setFormCustomer({
                customerName: data.customerName,
                phone: data.phone,
                mail: data.mail
            });
            setDiscountMoneyNow(data.discountMoney);
            setMoney(data.initialPrice);
            setBookingCode(data.bookingCode);
            setCreatedDate(moment(data.createdDate).format('DD/MM/YYYY'));
            setDiscountMoney(data.discountMoney || 0);
            setTotalMoney(data.totalMoney || 0);
        });
    };

    const getSupplier = () => {
        axios.get(`/supplier/info`).then((response) => {
          if (response.data.data.length > 0) {
            const data = response.data.data[0];
            setImages(data.image === null ? images : data?.image);
          }
        });
      };
    
      useEffect(() => {
        getSupplier()
      }, []);

    return (
        <Modal
            width="60%"
            title="Hóa Đơn"
            open={show}
            onCancel={handleCancel}
            footer={null}
        >
            <div className="invoice-box" ref={componentRef}>
                <table className="invoice-table">
                    <tbody>
                        <tr className="top">
                            <td className="title">
                                <img
                                    src={images ? `${REACT_APP_API_KEY_IMAGE}${images}` : logo}
                                    style={{ width: 'auto', height: 80 }}
                                    alt="Logo"
                                />
                            </td>
                            <td>
                                Hoá đơn<br />
                                Ngày tạo: {moment(new Date()).format('DD-MM-YYYY')}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h2>Thông tin dịch vụ</h2>
                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th>Tên dịch vụ</th>
                            <th>Giá dịch vụ</th>
                            <th>Nhân viên phục vụ</th>
                            <th>Thời gian bắt đầu</th>
                            <th>Thời gian kết thúc</th>
                            <th>Mã giảm giá</th>
                            <th>Số tiền giảm</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataBill.map((item, index) => (
                            <tr key={index}>
                                <td>{item.service}</td>
                                <td><b>{FormatMoney(item.price)} VND</b></td>
                                <td>{item.employee}</td>
                                <td>{moment(item.realTime).format('DD/MM/YYYY - HH:mm:ss')}</td>
                                <td>{moment(item.endTime).format('DD/MM/YYYY - HH:mm:ss')}</td>
                                <td>{item.voucherCode}</td>
                                <td>{FormatMoney(item.discountVoucher)} VND</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2>Thông tin Thành viên</h2>
                <div className="customer-info">
                    <p><strong>Họ tên:</strong> {formCustomer.customerName}</p>
                    <p><strong>Điện thoại:</strong> {formCustomer.phone}</p>
                    <p><strong>Email:</strong> {formCustomer.mail}</p>
                    <p><strong>Tổng tiền đã giảm:</strong> {FormatMoney(discountMoneyNow)} VND</p>
                    <p><strong>Tổng tiền gốc:</strong> {FormatMoney(money)} VND</p>
                    <p><strong>Tổng tiền:</strong> {FormatMoney(totalMoney)} VND</p>
                </div>
            </div>
            <div style={{
                display:'flex',
                justifyContent:'end',
                gap:"10px"
            }}>
                <Button onClick={handleCancel}>
                    Huỷ
                </Button>
                <Button className="no-print" onClick={handlePrint}>
                    In Ngay
                </Button>
            </div>
            
        </Modal>
    );
};

export default Invoice;
