import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Avatar, Breadcrumb, Button, Col, Input, List, notification, Row, Space, Tabs } from 'antd';
import {
  HomeOutlined,
  MessageOutlined,
  ReloadOutlined,
  SearchOutlined,
  StarOutlined,
} from '@ant-design/icons';

import Filter from '../../constant/Filter';
import FilterTime from '../../constant/FilterTime';
import CustomSreach from '../../constant/Sreach';
import { addCommentAdmin } from '../../reducers/feedBackReducer';
import axios from '../../services/Api';

import './FeedBack.css';

import dayjs from 'dayjs';
import moment from 'moment';

export default function FeedBackService() {
  const { TextArea } = Input;
  const dispatch = useDispatch();

  const supplierId = localStorage.getItem('supplierId');

  const [input, setInput] = useState('');
  const [totalPage, setTotalPage] = useState('');
  const [totalPageList, setTotalPageList] = useState('');
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sizeList, setSizeList] = useState(10);
  const [pageList, setPageList] = useState(0);
  const [serviceId, setServiceId] = useState('92929129031');
  const [checkId, setCheckId] = useState();
  const [data, setData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const allService = {
    id: 92929129031,
    name: 'Tất cả',
  };
  const [service, setService] = useState([]);
  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)} {text}
    </Space>
  );

  useEffect(() => {
    if (supplierId !== '') {
      getDataService();
    }
  }, []);

  useEffect(() => {
    if (checkId !== undefined) {
      getDataFeedBackServicesById(checkId);
    }
  }, [checkId, pageList, sizeList]);

  useEffect(() => {
    if (serviceId === '92929129031') {
      getDataFeedBackServicesAll();
    } else {
      getDataFeedBackServices();
    }
  }, [serviceId, page, size]);

  const getDataService = () => {
    axios.get(`/service/get/supplier?supplier=${supplierId}&page=0&size=10`).then((response) => {
      const arr = response?.data?.data;
      arr.unshift(allService);
      setService(arr);
    });
  };

  const getDataFeedBackServices = () => {
    axios
      .get(`feedback/get-service?serviceId=${serviceId}&page=${page}&size=${size}`)
      .then((response) => {
        setData(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const getDataFeedBackServicesAll = () => {
    axios
      .get(`feedback/get/all/service?supplierId=1&page=${page}&size=${size}`)
      .then((response) => {
        setData(response?.data?.data);
        setTotalPage(response?.data?.totalSize);
      });
  };

  const getDataFeedBackServicesById = (id) => {
    axios
      .get(`feedback/get/comment-service?id=${id}&page=${pageList}&size=${sizeList}`)
      .then((response) => {
        setDataList(response?.data?.data);
        setTotalPageList(response?.data?.totalSize);
      });
  };

  const onChangeSelect = (value) => {
    setServiceId(value);
  };

  const onShowSizeChange = (current, pageSize) => {
    setSize(pageSize);
  };

  const onShowSizeChangeList = (current, pageSize) => {
    setSizeList(pageSize);
  };

  const openComment = (id) => {
    setCheckId(id);
    setShowComment(!showComment);
  };

  const onChangeInput = (e) => {
    setInput(e.target.value);
  };

  const onSendComment = () => {
    if (input !== '') {
      const body = {
        id: checkId,
        comment: input,
      };
      dispatch(
        addCommentAdmin(body, {
          onSuccess: () => {
            setInput('');
          },
        })
      );
    } else {
      notification.error({
        message: `Bạn cần phải nhập nội dung`,
      });
    }
  };

  const onClickFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <Row span={24}>
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/'}>
                  {' '}
                  <HomeOutlined />{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <b>
                <Link style={{ color: '#808080' }} to={'/feedBackSupplier'}>
                  {' '}
                  Đánh giá Cộng đồng{' '}
                </Link>
              </b>
            </Breadcrumb.Item>
            <div style={{ color: 'black' }}>
              <b>Đánh giá Dịch vụ</b>
            </div>
          </Breadcrumb>
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 10 }}>
        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          <Filter onClickFilter={onClickFilter} />
        </Col>

        <Col span={24} style={{ borderBottom: '1px solid #d9d9d9' }}>
          {showFilter === true ? (
            <Row span={24} style={{ marginBottom: 5, marginTop: 10 }}>
              <Col xs={2} sm={3} md={3} lg={6} xl={6} xxl={7} />
              <Col xs={22} sm={20} md={20} lg={12} xl={13} xxl={12}>
                <Row span={24}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <FilterTime styleRangePicker={{ width: '60%' }} />
                  </Col>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {' '}
                    <CustomSreach styleInput={{ width: '80%' }} placeholder="Tìm kiếm theo tên" />
                  </Col>
                </Row>
                <Row span={24}>
                  <Button style={{ backgroundColor: '#406FBF', marginBottom: 5 }} type="primary">
                    <SearchOutlined /> Tìm kiếm
                  </Button>
                  <Button
                    style={{
                      marginLeft: 6,
                      backgroundColor: '#697689',
                      marginBottom: 5,
                    }}
                    type="primary"
                  >
                    <ReloadOutlined /> Quay lại
                  </Button>
                </Row>
              </Col>
              <Col xs={0} sm={1} md={1} lg={6} xl={5} xxl={5} />
            </Row>
          ) : null}
        </Col>
      </Row>

      <Row span={24} style={{ paddingTop: 5 }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={13}
          xxl={13}
          style={{ borderRight: '1px solid #d9d9d9', paddingBottom: 20 }}
        >
          <Tabs
            defaultActiveKey="1"
            size="small"
            onChange={onChangeSelect}
            items={service.map((item) => {
              const id = String(item?.id);
              console.log('key', id);
              return {
                label: `${item?.name}`,
                key: id,
              };
            })}
          />
          <div className="feedBackService">
            <List
              style={{ marginLeft: 10, marginRight: 10 }}
              itemLayout="vertical"
              size="small"
              pagination={{
                size: 'small',
                onChange: (page) => {
                  setPage(page - 1);
                },
                pageSize: size,
                total: totalPage,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
              }}
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  key={item?.id}
                  actions={[
                    <IconText icon={StarOutlined} text={item?.point} />,
                    <div
                      onClick={() => {
                        openComment(item.id);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <IconText icon={MessageOutlined} text={item?.totalComment} />
                    </div>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{
                          marginTop: 25,
                          borderRadius: 10,
                          width: 40,
                          height: 40,
                        }}
                        src={
                          item?.avatar !== undefined
                            ? item?.avatar
                            : 'https://cdn-icons-png.flaticon.com/512/4944/4944197.png'
                        }
                      />
                    }
                    title={
                      <Row span={24}>
                        <Col xs={24} sm={24} md={2} lg={2} xl={2} xxl={2}>
                          <divs
                            style={{
                              fontWeight: 'bold',
                              fontSize: '15px',
                            }}
                          >
                            {item?.username}
                          </divs>
                        </Col>
                        <Col xs={24} sm={24} md={22} lg={22} xl={22} xxl={22}>
                          <div
                            style={{
                              color: '#808080',
                              fontSize: '11px',
                              fontWeight: 'normal',
                              float: 'right',
                            }}
                          >
                            {' '}
                            {moment(item?.createTime).format('HH:mm:ss - DD/MM/YYYY')}
                          </div>
                        </Col>
                      </Row>
                    }
                    description={
                      <div
                        style={{
                          color: 'black',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {item?.note !== '' ? item?.note : 'Không có đánh giá'}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={11}
          xxl={11}
          style={{ borderLeft: '1px solid #d9d9d9' }}
        >
          <Row span={24}>
            <Col span={24} style={{ paddingBottom: 10 }}>
              <div className="listFeedBackService">
                <List
                  style={{ marginLeft: 10, marginRight: 10, marginTop: 52 }}
                  itemLayout="vertical"
                  size="small"
                  dataSource={dataList}
                  pagination={{
                    size: 'small',
                    position: checkId !== undefined ? 'bottom' : 'none',
                    onChange: (page) => {
                      setPageList(page - 1);
                    },
                    pageSize: sizeList,
                    total: totalPageList,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChangeList,
                    showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
                  }}
                  renderItem={(item) => (
                    <List.Item key={item?.id}>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            style={{
                              marginTop: 25,
                              borderRadius: 10,
                              width: 35,
                              height: 35,
                            }}
                            src={
                              item?.image !== undefined && item?.image !== null
                                ? item?.image
                                : 'https://cdn-icons-png.flaticon.com/512/4944/4944197.png'
                            }
                          />
                        }
                        title={
                          <Row span={24}>
                            <Col xs={24} sm={24} md={2} lg={2} xl={2} xxl={2}>
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '15px',
                                }}
                              >
                                {item?.admin !== true ? (
                                  <div style={{ color: '#555555' }}>{item?.name}</div>
                                ) : (
                                  <div style={{ color: '#0693E3' }}>Admin</div>
                                )}
                              </div>
                            </Col>
                            <Col xs={24} sm={24} md={22} lg={22} xl={22} xxl={22}>
                              <div
                                style={{
                                  color: '#808080',
                                  fontSize: '11px',
                                  fontWeight: 'normal',
                                  float: 'right',
                                }}
                              >
                                {' '}
                                {moment(item?.createTime).format('HH:mm:ss - DD/MM/YYYY')}
                              </div>
                            </Col>
                          </Row>
                        }
                        description={
                          <div
                            style={{
                              color: 'black',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {item?.note !== '' ? item?.comment : 'Không có đánh giá'}
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Col>

            <Col span={24} style={{ paddingBottom: 10 }}>
              {checkId !== undefined ? (
                <Row span="24">
                  <Col span={1} />
                  <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18}>
                    <TextArea
                      autoSize
                      style={{ border: '1px solid #0693E3', width: '90%' }}
                      placeholder="Aaa..."
                      onChange={onChangeInput}
                      value={input}
                    />
                  </Col>
                  <Col span={1} />
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Button
                      style={{
                        marginRight: 10,
                        float: 'right',
                        backgroundColor: '#0693E3',
                        border: '#0693E3',
                      }}
                      type="primary"
                      onClick={onSendComment}
                    >
                      Gửi phản hồi
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
