import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Badge, Col, Dropdown, Menu, Radio, Row } from 'antd';

import logoVBNOW from '../../assets/logovuong.png';
import { changeStatusNoti } from '../../reducers/notiReducer';
import axios from '../../services/Api';
import ModalBill from '../Bill/ModalBill';

import './BadgeNote.css';

import moment from 'moment';

export default function BadgeNote() {
  const dispatch = useDispatch();
  const history = useHistory();
  const supid = localStorage.getItem('supplierId');
  const [type, setType] = useState('all');
  const [status, setStatus] = useState(1);
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [pageScroll, setPageScroll] = useState(1);
  const [sizeScroll, setSizeScroll] = useState(10);
  const [total, setTotal] = useState(0);
  const [renderNoti, setRenderNoti] = useState(false);
  const [showView, setShowView] = useState({ show: false, bookingId: null });
  const [hasMore, setHasMore] = useState(true);

  const supplierId = useMemo(() => supid, [supid])


  

  

  useEffect(() => {
    setPage(0);
    setPageScroll(1);
    setSize(10);
    setSizeScroll(10);
    setRenderNoti(false);
    if(!!supplierId){
      getDataNoti();
    }

  }, [renderNoti === true, type, status,supplierId]);

  const getDataNoti = () => {
    if (type === 'all') {
      if (status === 3) {
        var body = { supplierId: supplierId };
      } else {
        var body = { status: status, supplierId: supplierId};
      }
    } else {
      if (status === 3) {
        var body = { type: type, supplierId: supplierId};
      } else {
        var body = { type: type, status: status, supplierId: supplierId };
      }
    }
    axios.post(`/history-notification/get?page=${page}&size=${size}`, body).then((response) => {
      let newDatas = response.data.data;
      setData(newDatas);
      setTotal(response?.data?.count);
      setHasMore(true);
    });
  };
  const getDataNotiInfiniteScroll = () => {
    if (type === 'all') {
      if (status === 3) {
        var body = { supplierId: supplierId };
      } else {
        var body = { status: status, supplierId: supplierId };
      }
    } else {
      if (status === 3) {
        var body = { type: type, supplierId: supplierId };
      } else {
        var body = { type: type, status: status, supplierId: supplierId };
      }
    }
    if (data.length != total) {
      axios
        .post(`/history-notification/get?page=${pageScroll}&size=${sizeScroll}`, body)
        .then((response) => {
          let newDatas = response.data.data;
          setData(data.concat([...newDatas]));
          setPageScroll((prev) => prev + 1);
        });
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };

  const onViewFalse = (id, data) => {
    const status = false;
    dispatch(
      changeStatusNoti(status, id, {
        onSuccess: (message) => {
          setRenderNoti(true);
        },
        onError: (message) => {},
      })
    );
    if (data?.type === 'booking') {
      setShowView({ show: true, bookingId: data?.id });
    } else {
      console.log('modal khác');
    }
  };

  const onCancelShowView = () => {
    setShowView({ show: false, bookingId: null });
  };

  const onChangeType = (e) => {
    setType(e.target.value);
  };

  const onViewAllNoti = () => {
    history.replace('./viewAllNoti');
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="Unread" onClick={() => setStatus(1)}>
        <div style={{ paddingLeft: '5px', color: '#333333' }}>
          <b>Chưa đọc</b>
        </div>
      </Menu.Item>
      <Menu.Item key="allRead" onClick={() => setStatus(3)}>
        <div style={{ paddingLeft: '5px', color: '#333333' }}>
          <b>Tất cả</b>
        </div>
      </Menu.Item>
      <Menu.Item key="viewAll" onClick={() => onViewAllNoti()}>
        <div style={{ paddingLeft: '5px', color: '#333333' }}>
          <b>Xem tất cả</b>
        </div>
      </Menu.Item>
    </Menu>
  );

  const listNoti = (
    <div
      style={{
        backgroundColor: '#f7f9fa',
        width: '500px',
        border: '2px solid #cccccc',
        borderRadius: '8px',
      }}
    >
      <div className="demo-infinite-container">
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          useWindow={false}
          hasMore={hasMore}
          loadMore={getDataNotiInfiniteScroll}
        >
          <Row span={24}>
            <Col span={24}>
              <div
                style={{
                  backgroundColor: '#f7f9fa',
                  height: '120px',
                  border: '2px solid #f7f9fa',
                  borderRadius: '5px',
                }}
              >
                <div style={{ height: '30px', width: '440px' }}>
                  <Row span={24}>
                    <Col span={20}>
                      <h2 style={{ marginLeft: '10px' }}>Thông báo</h2>
                    </Col>
                    <Col span={4}>
                      <div style={{ float: 'right' }}>
                        <Dropdown trigger={['click']} overlay={dropdownMenu}>
                          <div
                            style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                              paddingTop: '18px',
                            }}
                          >
                            <svg
                              t="1670984147773"
                              className="icon"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              p-id="4087"
                              width="22"
                              height="22"
                            >
                              <path
                                d="M896 640c-70.656 0-128-57.344-128-128s57.344-128 128-128 128 57.344 128 128-57.344 128-128 128z m-384 0c-70.656 0-128-57.344-128-128s57.344-128 128-128 128 57.344 128 128-57.344 128-128 128zM128 640c-70.656 0-128-57.344-128-128s57.344-128 128-128 128 57.344 128 128-57.344 128-128 128z"
                                fill=""
                                p-id="4088"
                              />
                            </svg>
                          </div>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div style={{ marginLeft: '10px' }}>
                        <Radio.Group defaultValue="all" buttonStyle="solid" onChange={onChangeType}>
                          <Radio.Button value="all">Tất cả</Radio.Button>
                          <Radio.Button value="booking">Booking</Radio.Button>
                          <Radio.Button value="feedback">Feedback</Radio.Button>
                          <Radio.Button value="other">Khác</Radio.Button>
                        </Radio.Group>
                      </div>
                    </Col>
                  </Row>
                  <br />
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="custom-menu">
                {data?.map((item) => (
                  <div onClick={() => onViewFalse(item.id, item)}>
                    <Menu style={{ marginTop: 10 }}>
                      <Menu.Item
                        key={item.id}
                        className="active-menu"
                        style={{
                          marginLeft: '10px',
                          height: '80px',
                          padding: '10px',
                          borderBottom: '1px',
                        }}
                      >
                        <Row span={24}>
                          <Col
                            style={{
                              width: '20%',
                              height: '100%',
                              float: 'left',
                              zIndex: 2,
                            }}
                          >
                            <img
                              className="conversation-photo"
                              src={logoVBNOW}
                              alt="conversation"
                            />
                          </Col>
                          <Col
                            style={{
                              width: '80%',
                              height: '100%',
                              float: 'left',
                            }}
                          >
                            <h4
                              className={
                                item.status == 1
                                  ? 'conversation-title-unread'
                                  : 'conversation-title'
                              }
                            >
                              {item?.title}
                              <span className="chat-time">
                                {moment(item?.createdDate).format('DD-MM-YYYY')}
                              </span>
                            </h4>
                            <div
                              className={item.status == 1 ? 'fix-line-css-unread' : 'fix-line-css'}
                            >
                              {item?.content}
                            </div>
                            {item?.status == 1 ? (
                              <div
                                style={{
                                  width: '10%',
                                  float: 'right',
                                }}
                              >
                                <svg
                                  t="1670903881489"
                                  className="icon"
                                  viewBox="0 0 1064 1024"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  p-id="3450"
                                  width="12"
                                  height="12"
                                >
                                  <path
                                    d="M512 0c282.781538 0 512 229.218462 512 512S794.781538 1024 512 1024s-512-229.218462-512-512S229.218462 0 512 0z"
                                    p-id="3451"
                                    fill="#37B59D"
                                  />
                                </svg>
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </Menu.Item>
                    </Menu>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );

  return (
    <>
      <div className="svg-icon" style={{ marginTop: '12px' }}>
        <Badge size="small" count={total}>
          <Dropdown trigger={['click']} overlay={listNoti}>
            <svg
              t="1670985177679"
              className="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2692"
              width="30"
              height="30"
            >
              <path
                d="M512 1024c70.64 0 127.94-57.3 127.94-128H384.06c0 70.7 57.3 128 127.94 128z m430.78-299.42c-38.64-41.52-110.94-103.98-110.94-308.58 0-155.4-108.96-279.8-255.88-310.32V64c0-35.34-28.64-64-63.96-64s-63.96 28.66-63.96 64v41.68C301.12 136.2 192.16 260.6 192.16 416c0 204.6-72.3 267.06-110.94 308.58-12 12.9-17.32 28.32-17.22 43.42 0.22 32.8 25.96 64 64.2 64h767.6c38.24 0 64-31.2 64.2-64 0.1-15.1-5.22-30.54-17.22-43.42z"
                fill="#515151"
                p-id="2693"
              />
            </svg>
          </Dropdown>
        </Badge>
      </div>

      {/* <ModalBill
        show={showView.show}
        bookingId={showView.bookingId}
        handleCancel={onCancelShowView}
      /> */}
    </>
  );
}
