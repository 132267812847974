import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Breadcrumb, Button, Col, Form, Input, notification, Row, Upload } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import CustomSelect from '../../constant/Select';
import { addService } from '../../reducers/serviceVbReducer';
import axios from '../../services/Api';

import './ServiceVb.css';
import { REACT_APP_API_KEY } from '../../constant/data';

const typePrices = [
  // {
  //   key: 0,
  //   value: 0,
  //   label: 'Giá tiền',
  // },
  {
    key: 1,
    value: 1,
    label: 'Giá tiền',
  },
  {
    key: 2,
    value: 2,
    label: 'Khoảng giá',
  },
  {
    key: 3,
    value: 3,
    label: 'Giá liên hệ',
  },
];

const InsServiceVb = () => {
  const { TextArea } = Input;
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const initState = {
    groupId: null,
    type: null,
  };

  const supplierId = localStorage.getItem('supplierId');

  const [fieldState, setFieldState] = useState(initState);
  const [grService, setGrService] = useState([]);
  const grServiceList = grService?.map((e, i) => ({ key: e.id, value: e.id, label: e.name }));
  const [listImages, setListImages] = useState([]);
  const [fileList, setFileList] = useState([]);

  const JWT_HEADER = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  });

  useEffect(() => {
    getDataGrService();
  }, []);

  const getDataGrService = () => {
    axios.get(`/group-service/get?supplierId=${supplierId}&page=0&size=1000`).then((response) => {
      setGrService(response?.data?.data);
    });
  };

  const onIns = (values) => {
    if (fieldState?.groupId !== null) {
      const body = {
        name: values?.name,
        status: true,
        price: values?.price,
        maxPrice: values?.maxPrice,
        minPrice: values?.minPrice,
        discount: values?.discount,
        serviceTime: values?.serviceTime,
        description: values?.description,
        supplierId: supplierId,
        groupId: fieldState?.groupId,
        type: fieldState?.type,
        image:
          listImages.length > 0
            ? listImages
            : [
                '/root/vbnow/product_images/member-avatars/c85fc9ec-bc3c-45d4-8d98-f40dc9b27cd2.jpg',
              ],
      };
      dispatch(
        addService(body, {
          onSuccess: (message) => {
            notification.success({
              message: `Thêm dịch vụ thành công`,
            });
            history.push('/serviceVb');
          },
          onError: (message) => {
            notification.error({
              message: `Thêm thất bại`,
            });
          },
        })
      );
    } else {
      notification.warning({
        message: `Chọn nhóm dịch vụ`,
      });
    }
  };

  const onChangeSelect = (ev, keyName) => {
    setFieldState((prev) => ({
      ...prev,
      [keyName]: ev,
    }));
    form.setFieldsValue({ [keyName]: ev });
  };

  const handleChangeImage = (info) => {
    setFileList(info?.fileList);
    let fileList = [...info.fileList];
    const urlList = fileList.map((file) => {
      return file.response?.data;
    });
    setListImages(urlList);
  };

  const onPreview = async (file) => {
    const link = file?.response?.image;
    window.open(`${REACT_APP_API_KEY}${link}`, '_blank');
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <b>
              <Link style={{ color: '#808080' }} to={'/'}>
                {' '}
                <HomeOutlined />{' '}
              </Link>
            </b>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: '#808080' }} to={'/serviceVb'}>
                {' '}
                Danh sách dịch vụ
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: 'black' }}>
            <b>Thêm dịch vụ</b>
          </div>
        </Breadcrumb>
      </Row>

      <Row span={24} style={{ marginTop: 10 }}>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={22} lg={18} xl={18} xxl={18}>
          <Form
            form={form}
            onFinish={onIns}
            autoComplete="off"
            labelCol={{ xs: 24, sm: 11, md: 8, lg: 6, xl: 6, xxl: 4 }}
            wrapperCol={{ xs: 24, sm: 13, md: 16, lg: 18, xl: 18, xxl: 20 }}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Banner" style={{ fontWeight: '500' }}>
                  <Upload
                    multiple
                    action={`${REACT_APP_API_KEY}/auth/test-public/upload-file`}
                    headers={JWT_HEADER}
                    method="POST"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleChangeImage}
                    onPreview={onPreview}
                  >
                    {fileList.length < 5 && '+ Tải lên'}
                  </Upload>
                </Form.Item>
                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Thời gian dịch vụ"
                  name="serviceTime"
                  rules={[{ required: false, message: 'Nhập thời gian dịch vụ' }]}
                >
                  <Input placeholder="Thời gian dịch vụ" suffix="phút" />
                </Form.Item>
                <Form.Item
                  label="Nhóm dịch vụ"
                  style={{ fontWeight: '500' }}
                  name="groupId"
                  rules={[{ required: true, message: 'Chọn nhóm dịch vụ' }]}
                >
                  <div className="selectServiceVb">
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Chọn nhóm dịch vụ"
                      value={fieldState.groupId}
                      options={grServiceList}
                      keyName="groupId"
                      onChange={onChangeSelect}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Tên dịch vụ"
                  name="name"
                  rules={[{ required: true, message: 'Nhập tên dịch vụ' }]}
                >
                  <TextArea autoSize placeholder="Tên dịch vụ" />
                </Form.Item>

                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Loại giá"
                  name="type"
                  rules={[{ required: true, message: 'Chọn loại giá' }]}
                >
                  <div className="selectServiceVb">
                    <CustomSelect
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Chọn loại giá dịch vụ"
                      value={fieldState.type}
                      options={typePrices}
                      keyName="type"
                      onChange={onChangeSelect}
                    />
                  </div>
                </Form.Item>

                {fieldState.type != 2 && fieldState.type != 3 && (
                  <Form.Item
                    style={{ fontWeight: '500' }}
                    label="Giá dịch vụ"
                    name="price"
                    rules={[{ required: true, message: 'Nhập giá dịch vụ' }]}
                  >
                    <Input placeholder="Giá dịch vụ" suffix="VND" />
                  </Form.Item>
                )}
                {fieldState.type != 2 && fieldState.type != 3 && (
                  <Form.Item
                    style={{ fontWeight: '500' }}
                    label="Giá giảm"
                    name="discount"
                    rules={[{ required: false, message: 'Nhập giá dịch vụ' }]}
                  >
                    <Input placeholder="Giá dịch vụ" suffix="VND" />
                  </Form.Item>
                )}
                {(fieldState.type == 2 || fieldState.type == 3) && (
                  <div>
                    <Form.Item
                      style={{ fontWeight: '500' }}
                      label="Giá dịch vụ tối thiểu"
                      name="minPrice"
                      rules={[
                        {
                          required: fieldState.type === 3 ? false : true,
                          message: 'Nhập giá dịch vụ',
                        },
                      ]}
                    >
                      <Input placeholder="Giá dịch vụ" suffix="VND" />
                    </Form.Item>
                    <Form.Item
                      style={{ fontWeight: '500' }}
                      label="Giá dịch vụ tối đa"
                      name="maxPrice"
                      rules={[
                        {
                          required: fieldState.type === 3 ? false : true,
                          message: 'Nhập giá dịch vụ',
                        },
                      ]}
                    >
                      <Input placeholder="Giá dịch vụ" suffix="VND" />
                    </Form.Item>
                  </div>
                )}

                <Form.Item
                  style={{ fontWeight: '500' }}
                  label="Mô tả"
                  name="description"
                  rules={[{ required: false, message: 'Nhập mô tả' }]}
                >
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="Mô tả" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  style={{
                    marginLeft: '6px',
                    backgroundColor: 'rgb(55, 181, 157)',
                    border: 'rgb(55, 181, 157)',
                    float: 'right',
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Xác nhận
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={1} lg={4} xl={4} xxl={4} />
      </Row>
    </>
  );
};
export default InsServiceVb;
