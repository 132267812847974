import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Input, Modal, notification } from 'antd';

import { addGroup } from '../../reducers/grService';
import axios from '../../services/Api';

export default function UpdGroup(props) {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { show, handleCancel, id } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (id !== null) {
      getDataById();
    }
  }, [show, id]);

  const getDataById = () => {
    axios.get(`/group-service/get/id?id=${id}`).then((response) => {
      form.setFieldsValue(response?.data?.data);
    });
  };
  const onShowUpd = (values) => {
    const body = {
      id: id,
      name: values?.name,
      status: true,
      description: values?.description,
    };
    dispatch(
      addGroup(body, {
        onSuccess: (message) => {
          notification.success({
            message: `Sửa nhóm  dịch vụ thành công`,
          });
          handleCancelUpd();
        },
        onError: (message) => {
          notification.error({
            message: `Sửa thất bại`,
          });
        },
      })
    );
  };

  const handleCancelUpd = () => {
    handleCancel();
    form.setFieldsValue({
      name: '',
      description: '',
    });
  };

  return (
    <Modal
      title={`SỬA THÔNG TIN NHÓM DỊCH VỤ  `}
      open={show}
      onCancel={handleCancel}
      okButtonProps={{
        form: 'UpdGroupService',
        key: 'submit',
        htmlType: 'submit',
      }}
    >
      <Form
        form={form}
        id="UpdGroupService"
        onFinish={onShowUpd}
        autoComplete="off"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Tên nhóm dịch vụ "
          name="name"
          rules={[{ required: true, message: 'Nhập tên nhóm  dịch vụ' }]}
        >
          <TextArea autoSize placeholder="Tên nhóm  dịch vụ" />
        </Form.Item>
        <Form.Item
          style={{ fontWeight: '500' }}
          label="Mô tả"
          name="description"
          rules={[{ required: true, message: 'Nhập mô tả' }]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="Mô tả" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
